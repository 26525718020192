import React from 'react';
import AllureLogo from '../components/AllureLogo';
import { randomString } from '../helpers/generateRandomNumber';
import { getDateToday } from '../helpers/getDateToday';
const SuccessPage = () => {
 
  return (
      <div className="success h-full w-full flex">
           <div className="success_container md:container mx-auto flex flex-col items-center justify-center">
           <AllureLogo/>
            <div className="success_content my-8">
              <h1 className="success_content_title py-8">You have 1 Free Beverage! <br/> <span className='uppercase'>{randomString(10)}</span></h1>
            </div>
            <div className="success_content_please">
              <h3 className="success_content_please_title pb-8">Please display this screen to the cashier.</h3>
              <span className="success_content_please_under-line"></span>
            </div>
            <div className="success_footer">
              <p className="success_footer_title">Terms</p>
              <ul className="success_footer_list list-disc ml-5">
                <li className="success_footer_list_item">Redeemable at Spring Cafe Aspen</li>
                <li className="success_footer_list_item">Not valid with any other discounts and promotions</li>
                <li className="success_footer_list_item">Valid for a beverage only</li>
                <li className="success_footer_list_item">No cash value</li>
                <li className="success_footer_list_item">Valid only {getDateToday()}</li>
              </ul>
            </div>
        </div>
      </div>
  );
}

export default SuccessPage;