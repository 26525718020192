import React, { useRef, useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { expression } from '../helpers/regex';


const AllureForm = () => {
  const [subscribed, setSubscribed] = useState(false)
  const [loading, setLoading] = useState(false)
  const [ErrorEmail, setErrorEmail] = useState('')
  const [ErrorPhone, setErrorPhone] = useState('')
  const [ErrorCheck, setErrorCheck] = useState('')
  const [numberInternationalPhone, setnumberInternationalPhone] = useState()
  const $emailInput = useRef()
  const $CheckboxInput = useRef()
  const submit = (event) => {
    event.preventDefault();
    let email = $emailInput.current.value;
    let checkbox = $CheckboxInput.current.checked;
    let phone = numberInternationalPhone;


    if (!expression.test(email) ) {
      setSubscribed(false)
      setErrorEmail('Please Enter Valid Email')
    }else if (isValidPhoneNumber(`${phone}`) != true) {
      setErrorPhone('Please Enter Valid Phone Number.')
      setSubscribed(false)
      }else if (checkbox != true) {
      setErrorCheck('You need to accept the terms to continue.')
      setSubscribed(false)
    } else{
        const formData = new URLSearchParams() // submit the form lie search params using POST
        formData.append("g", "UDV8UQ")
        formData.append(
          "$fields",
          "$source,$email,$consent_method,$consent_form_id,$consent_form_version"
        )
        formData.append("$list_fields", "")
        formData.append("$source", "Website Coffee Allure Shop")
        formData.append("$email", email)
        formData.append("$phone_number", numberInternationalPhone)
        formData.append("$consent_method", "Klaviyo Form")
        formData.append("$consent_form_id", "QQ2LFw")
        formData.append("$consent_form_version", "2272967")

        fetch(`https://a.klaviyo.com/ajax/subscriptions/subscribe`, {
          method: "POST",
          body: formData,
          
        })
          .then(() => {
            setSubscribed(true);
            window.location.href = "/successful";
          })
          .catch(error => {
            console.log(error)
            setSubscribed(false)
          })
          .finally(() => {
            setLoading(false)
        })
    }
    //klaviyo form opt-in
}
  return (
    <>
    <form className="klavito_form flex items-center justify-center flex-col" onSubmit={submit}>
      <h4 className="klavito_form__title">Free Beverage</h4>
      <div className="group_input">
      
      <input
        className={`klavito_form_input klaviyo_form__email  ${!subscribed && ErrorEmail ? 'input_error_focus' : ''}`}
        placeholder="Email"
        ref={$emailInput}
        type="email"
        required
      />
      {!subscribed && <p className="klavito_form_error">{ErrorEmail}</p>}
      </div>
      <div className="group_input">
      <PhoneInput
        defaultCountry="US"
        placeholder="Enter phone number"
        value={numberInternationalPhone}
        onChange={setnumberInternationalPhone}
        className={`klavito_form_input ${!subscribed && ErrorPhone ? 'input_error_focus' : ''}`}
        />
        {!subscribed && <p className="klavito_form_error">{ErrorPhone}</p>}
      </div>
      
      <label className='flex items-center klavito_form__label'>
        <input 
            type="checkbox" 
            ref={$CheckboxInput}   
            className={`${!subscribed && ErrorCheck ? 'input_error_focus-checkbox' : ''}`}  
            id="allure_checkbox" /> Send me special offers through text and email
      </label>

      <p className='klavito_form__conditions ml-2 mb-3'>I agree to receive recurring automated marketing text messages (e.g. cart reminders) and emails at the phone number and email address provided. Consent is not a condition to purchase. Promotion is limited to 1 per customer. Msg & data rates may apply. Msg frequency varies. Reply HELP for help and STOP to cancel. View our Terms of Service and Privacy Policy.
    </p>
    {!subscribed && <p className="klavito_form_error">{ErrorCheck}</p>}
      <button
      type="submit"
      className="klavito_form__button"
      aria-label="Subscribe"
      disabled={loading}
      >GET FREE BEVERAGE</button>
    </form>
    </>
  );
}

export default AllureForm;